import API from '@/network/API'
import { CustomSuccessData } from 'axios'
import UserInfo from '@/utils/account-manager/models/UserInfo'
import UserShopPoints from '../models/UserShopPoints'
import ChannelManager from '@/utils/channel-manager'

const _url = '/user/v1.0'
const personalInformationUrl = `${_url}/personalInformation`

async function fetchUserInformation(): Promise<CustomSuccessData<UserInfo>> {
  return API.instance().get<UserInfo>(personalInformationUrl)
}

function _buildUserShopPointsUrl(shopId: string): string {
  const channelManager = ChannelManager.instance()
  const pointChannel = channelManager.getChannel()
  return `/restaurantOrders/v1.0/points/restaurants/${shopId}?channel=${pointChannel}`
}

async function fetchUserShopPoints(shopId: string): Promise<CustomSuccessData<UserShopPoints>> {
  return API.instance().get<UserShopPoints>(_buildUserShopPointsUrl(shopId))
}

export { fetchUserInformation, fetchUserShopPoints }
