import axios, { AxiosInstance, CustomSuccessData } from 'axios'
import { Ref } from 'vue'
import TimeManager from '@/network/utils/TimeManager'
import APIError from './utils/APIError'
import { Modal } from 'ant-design-vue'
import { API_TIME_OUT } from './utils/API-time-out'

type APIConfig = { isAuthorization?: boolean, isSpin?: boolean, sign?: string, timestamp?: string }

export default class API {
  private static _api: API

  static instance(): API {
    if (this._api === undefined) {
      this._api = new API()
    }

    return this._api
  }

  private readonly _headers: { [key: string]: string } = {}
  private _requestCount = 0
  private _axiosInstance: AxiosInstance
  private _isSpin: Ref<boolean> | undefined = undefined
  private _isShowRetry = false

  private constructor() {
    this._axiosInstance = axios.create({
      timeout: API_TIME_OUT * 1000,
      baseURL: process.env.NODE_ENV === 'development' || process.env.VUE_APP_ENV === 'dev' ? 'https://api.yammii.net' : 'https://dmucbz3fj4pi3.cloudfront.net'
    })

    this._axiosInstance.interceptors.response.use(response => {
      const responseConfig = response.config as { isSpin?: boolean }

      if (responseConfig.isSpin === true) {
        this._spinningControl(-1)
      }

      TimeManager.instance().sync(response.headers?.date)

      const { status, data, message } = response.data
      if (status === 200) {
        return response
      }

      // 处理积分余额不足错误码 60402
      if (status === 60402) {
        // message为当前用户可用积分，提示用户积分不足，并显示当前用户可用积分
        throw new APIError(status, `status code: ${status}, message: ${message || data}`)
      }

      // data?.restaurantId: 专门用于扫码接口返回10018: {data: {restaurantId: "1100235105266020356"}, message: "device is stop,no heartbeat has been received.", status: 10018}
      throw new APIError(status, `status code: ${status}, message: ${message}`, data?.restaurantId)
    }, error => {
      if (error.config.isSpin === true) {
        this._spinningControl(-1)
      }
      if (error.config && error.response && (error.response.data.status === 102 || error.status === 401)) {
        localStorage.clear()
        window.location.reload()
      } else if (error.message.indexOf('timeout of') === 0) {
        if (!this._isShowRetry) {
          this._isShowRetry = true
          Modal.warning({
            title: 'Unstable network',
            content: 'Bad network, please use mobile data and try again',
            okText: 'Retry',
            centered: true,
            onOk: () => window.location.reload()
          })
        }
      } else {
        throw error
      }
    })

    this._axiosInstance.interceptors.request.use(config => {
      const requestConfig = config as { isSpin?: boolean }
      if (requestConfig.isSpin === true) {
        this._spinningControl(1)
      }

      return config
    })
  }

  private _spinningControl(num: number): void {
    this._requestCount += num

    if (this._requestCount < 0) {
      this._requestCount = 0
    }

    if (this._isSpin !== undefined) {
      if (this._requestCount === 0) {
        this._isSpin.value = false
      }

      if (this._requestCount === 1) {
        this._isSpin.value = true
      }
    }
  }

  set isSpin(spin: Ref<boolean>) {
    this._isSpin = spin
  }

  set authorizationToken(token: string) {
    if (token !== '') {
      this._headers.Authorization = `Bearer ${token}`
    } else {
      delete this._headers.Authorization
    }
  }

  // eslint-disable-next-line
  private _updateConfig(apiConfig?: APIConfig): { headers?: any, isSpin?: boolean } {
    // eslint-disable-next-line
    const config: { headers?: any, isSpin?: boolean } = {}
    config.isSpin = apiConfig?.isSpin ?? true
    const isAuthorization = apiConfig?.isAuthorization ?? true

    config.headers = isAuthorization ? this._headers : {}
    if (apiConfig?.sign !== undefined) {
      config.headers.sign = apiConfig.sign
    }
    if (apiConfig?.timestamp !== undefined) {
      config.headers.timestamp = apiConfig.timestamp
    }

    return config
  }

  // eslint-disable-next-line
  async delete<T>(url: string, params?: any, apiConfig?: APIConfig): Promise<CustomSuccessData<T>> {
    try {
      const config = {
        ...this._updateConfig(apiConfig),
        params
      }
      const response = await this._axiosInstance.delete(url, config)
      return response.data
    } catch (error) {
      return Promise.reject(error)
    }
  }

  // eslint-disable-next-line
  async get<T>(url: string, params?: any, apiConfig?: APIConfig): Promise<CustomSuccessData<T>> {
    try {
      const config = {
        ...this._updateConfig(apiConfig),
        params
      }
      const response = await this._axiosInstance.get(url, config)
      return response.data
    } catch (error) {
      return Promise.reject(error)
    }
  }

  // eslint-disable-next-line
  async post<T>(url: string, data?: any, apiConfig?: APIConfig): Promise<CustomSuccessData<T>> {
    try {
      const config = this._updateConfig(apiConfig)
      const response = await this._axiosInstance.post(url, data, config)
      return response.data
    } catch (error) {
      return Promise.reject(error)
    }
  }

  // eslint-disable-next-line
  async put<T>(url: string, data?: any, apiConfig?: APIConfig): Promise<CustomSuccessData<T>> {
    try {
      const config = this._updateConfig(apiConfig)
      const response = await this._axiosInstance.put(url, data, config)
      return response.data
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
