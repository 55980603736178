import Resolver from './resolvers/Resolver'
import ResolveResult from './resolvers/ResolveResult'

/**
 * 路径解析管理器，负责协调多个路径解析器的执行流程
 *
 * @class ResolverManager
 * @description
 * - 实现责任链模式的链式调用管理
 * - 维护解析器实例集合
 * - 提供统一的解析入口
 */
export default class ResolverManager {
  /** 当前注册的解析器实例集合，按添加顺序排列 */
  private _resolvers: Resolver[] = []
  /** 从URL解析得到的原始查询参数集合 */
  private readonly _pathQuery: { [key: string]: string }

  constructor (pathQuery: { [key: string]: string }) {
    this._pathQuery = pathQuery
  }

  /**
   * 添加解析器到责任链，自动维护链式关系
   * @param {Resolver} resolver - 要添加到链中的解析器实例
   * @description
   * - 设置解析器的路径查询参数
   * - 维护解析器链的顺序关系
   * - 最新添加的解析器会成为链尾
   */
  addResolver (resolver: Resolver): void {
    resolver.pathQuery = this._pathQuery
    this._resolvers.push(resolver)
    const resolversLength = this._resolvers.length

    if (resolversLength > 1) {
      this._resolvers[resolversLength - 2].nextResolver = resolver
    }
  }

  /**
   * 启动路径解析流程
   * @returns {Promise<ResolveResult>} 解析结果对象
   * @description
   * - 从责任链首节点开始解析
   * - 当所有解析器都无法处理时返回isResolved=false
   * - 统一处理解析过程中可能抛出的异常
   */
  async resolve (): Promise<ResolveResult> {
    if (this._resolvers.length > 0) {
      try {
        const result = await this._resolvers[0].resolve()
        return Promise.resolve(result)
      } catch (e) {
        return Promise.reject(e)
      }
    }

    return Promise.resolve({ isResolved: false })
  }
}
