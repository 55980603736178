import Resolver from '@/views/app/utils/path-resolver/resolvers/Resolver'
import ResolveResult from '@/views/app/utils/path-resolver/resolvers/ResolveResult'
import QRCodeType from '@/views/app/utils/path-resolver/models/QRCodeType'
import RestaurantInfoManager from '@/views/restaurant-information/utils/restaurant-info-manager'

/**
 * 店铺信息路径解析器，专门处理店铺详情页的二维码路径解析
 *
 * @class ShopInformationPathResolver
 * @extends Resolver
 * @description
 * - 识别并处理类型为QRCodeType.ShopDetail的二维码
 * - 负责设置餐厅信息管理器的参数
 * - 匹配失败时交由后续解析器处理
 */
export default class ShopInformationPathResolver extends Resolver {
  /**
   * 执行具体的路径解析逻辑
   *
   * @returns {Promise<ResolveResult>} 路径解析结果
   * @description
   * - 验证必须参数: type(t) 和 rid(shopId)
   * - 检查type是否为店铺详情类型(QRCodeType.ShopDetail)
   * - 设置餐厅信息管理器的参数并返回解析成功结果
   * - 参数缺失或类型不匹配时返回未解析状态
   *
   * @throws 不会主动抛出异常，所有错误都通过返回isResolved=false处理
   */
  resolvePath(): Promise<ResolveResult> {
    if (this._pathQuery !== undefined) {
      const { t: type, rid: shopId } = this._pathQuery

      if (type !== undefined && shopId !== undefined) {
        if (parseInt(type) !== QRCodeType.ShopDetail) {
          return Promise.resolve({ isResolved: false })
        } else {
          // 设置餐厅信息并返回成功解析结果
          RestaurantInfoManager.instance().setParams(shopId, '0')
          return Promise.resolve({
            isResolved: true,
            pathName: 'restaurantInformation'
          })
        }
      }
    }

    // 参数不完整时直接返回未解析状态
    return Promise.resolve({ isResolved: false })
  }
}
