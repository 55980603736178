import Resolver from '@/views/app/utils/path-resolver/resolvers/Resolver'
import ResolveResult from '@/views/app/utils/path-resolver/resolvers/ResolveResult'
import { fetchShopIdByDomain } from '../api'
import ShopInformationManager from '@/utils/shop-information-manager/ShopInformationManager'
// import { currentMenuId } from '@/utils/menu-manager/utils/menu-builder'
import OrderInformationManager from '@/utils/order-information-manager'
// import RestaurantInfoManager from '@/views/restaurant-information/utils/restaurant-info-manager'
import { getURLComponent } from '@/utils/helper/get-url-componet'
import { buildSessionOnlineOrder } from '@/utils/order-information-manager/utils/build-session-order-model'
import OnlineOrderModeType from '@/utils/shop-information-manager/models/OnlineOrderModeType'
import RestaurantInfoManager from '@/views/restaurant-information/utils/restaurant-info-manager'

/**
 * 在线订单域名解析器，处理通过域名访问的在线订单
 *
 * @class OnlineOrderDomainResolver
 * @extends Resolver
 * @description
 * - 解析通过域名或自定义路径访问的在线订单请求
 * - 支持通过餐厅名称参数或URL路径访问
 * - 根据域名或路径获取对应的店铺ID
 * - 验证店铺是否启用在线订单功能
 * - 根据店铺的在线订单模式返回不同的页面路径
 */
export default class OnlineOrderDomainResolver extends Resolver {
  /**
   * 执行在线订单域名解析逻辑
   *
   * @returns {Promise<ResolveResult>} 路径解析结果
   * @description
   * - 获取当前URL和路径信息
   * - 从查询参数或路径中提取餐厅名称或店铺ID
   * - 如果餐厅名称不是店铺ID格式(19位数字)，则通过API获取对应的店铺ID
   * - 验证店铺是否启用在线订单功能
   * - 创建会话在线订单信息
   * - 根据店铺的在线订单模式设置不同的跳转路径:
   *   - PlaceOrder模式: 跳转到取餐/配送时间页面
   *   - 其他模式: 跳转到餐厅信息页面
   * - 当没有有效的域名/路径信息时返回首页
   *
   * @throws {Error} 当店铺未启用在线订单功能时抛出错误
   */
  async resolvePath(): Promise<ResolveResult> {
    const url = window.location.href
    const path = getURLComponent(url, 'path')
    const pathQuery = this._pathQuery

    if (pathQuery !== undefined) {
      const shopName = pathQuery.restaurantName
      if (
        (path !== undefined &&
          path !== '' &&
          path !== 'index.html' &&
          Object.keys(pathQuery).length === 0) ||
        shopName !== undefined
      ) {
        try {
          const domainName =
            shopName !== undefined ? shopName : (path as string)
          let shopId = domainName
          if (!/^\d{19}$/.test(domainName)) {
            const { data } = await fetchShopIdByDomain(domainName)
            shopId = data.id
          }
          const shopInformation = await ShopInformationManager.instance().fetchShopInformation(
            shopId
          )
          if (!shopInformation.posModule.isOnlineOrderingEnabled) {
            return Promise.reject(new Error('onlineOrderNotEnabled'))
          }
          if (!shopInformation.onlineOrderingSetting.isOnlineOrderingEnabled) {
            return Promise.reject(new Error('posOnlineOrderNotEnabled'))
          }

          // const menuId = await currentMenuId(shopId, true)
          // if (menuId === undefined) {
          //   RestaurantInfoManager.instance().setParams(shopId, '1')
          //   return Promise.resolve({
          //     isResolved: true,
          //     pathName: 'restaurantInformation'
          //   })
          // }
          OrderInformationManager.instance().setSessionOnlineOrder(
            buildSessionOnlineOrder(shopId, undefined, true)
          )

          if (
            shopInformation.onlineOrderingSetting.onlineOrderMode ===
            OnlineOrderModeType.PlaceOrder
          ) {
            return {
              isResolved: true,
              pathName: 'pickupDeliveryTime'
            }
          }
          RestaurantInfoManager.instance().setParams(shopId)
          return {
            isResolved: true,
            pathName: 'restaurantInformation'
          }
        } catch (e) {
          return Promise.reject(e)
        }
      } else {
        return {
          isResolved: true,
          pathName: 'Home'
        }
      }
    }
    return Promise.resolve({ isResolved: false })
  }
}
