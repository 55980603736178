import ShopInformation from '../shop-information-manager/models/ShopInformation'
import HistoryOrder from '@/views/order-history/models/HistoryOrder'
import Shop from '@/views/favorite-shops/models/Shop'
import BaseInformationInterface from '@/views/restaurant-information/models/BaseInformationInterface'
import ShopShortInformation from '@/utils/shop-information-manager/models/ShopShortInformation'

const formatPhone = (phone?: string): string =>
  (phone ? phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : '')

const formatAddress = (shop?: ShopInformation | HistoryOrder | Shop | BaseInformationInterface | ShopShortInformation): string => {
  const {
    street = '',
    city = '',
    state = '',
    zipcode = ''
  } = shop ?? {}
  return [street, city, state, zipcode].filter(value => value !== '').join(', ')
}

// 格式化小于1000的数字为00X, 示例：009
const formatDigit = (num: number | string, length = 3, hasPrefix = true): string => {
  const value = `${num}`
  const formatString = value.length < length ? (Array(length).join('0') + num).slice(-length) : value
  return hasPrefix ? `#${formatString}` : formatString
}

// 格式化数字，示例：
// formatNumber(4.5000000) // 4.5
// formatNumber(4.5200000) // 4.52
// formatNumber(4.0000000) // 4
const formatNumber = (num: number): string => (num / 100).toFixed(2).replace(/\.?0+$/, '')

// 验证手机号是否合法
const validatePhone = (phone: string): boolean => /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(phone) || /^1[0-9]{10}$/.test(phone)

// 验证邮箱是否合法
const validateEmail = (email: string): boolean => /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(email)

// 验证菜品自定义数量是否合法：整数，或仅能有1-2位小数
const validateNumber = (num: string): boolean => /^[0-9]\d*(?:\.\d{1,2}|\d*)$/.test(num)

// 验证邮编是否合法
const validateZipCode = (zipCode: string): boolean => /^[0-9]{5}$/.test(zipCode)

// 验证手机验证码
const validateVerificationCode = (code: string): boolean => /^\d{4}$/.test(code)

// 用餐人数/餐具数量是否合法
const validateCount = (count: string): boolean => /^[1-9][0-9]?$/.test(count)

// 金额输入验证, 大于0，全数字，最多可以有两位小数
const validateDot2Number = (num: string): boolean => /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/.test(num)

// eslint-disable-next-line promise/param-names
const waitMoment = (m: number): Promise<void> => new Promise<void>(r => setTimeout(r, m))

const md5 = (encryptStr: string): string => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const Hashes = require('jshashes')
  return new Hashes.MD5().hex(encryptStr)
}

const generateSign = (encryptStr: string, key = 'AAAA98069FEA071D00E4BCC6734A22D9'): string => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const Hashes = require('jshashes')
  const SHA256 = new Hashes.SHA256()

  return SHA256.hex_hmac(key, encryptStr)
}

function judgeScreenType(): boolean {
  const rate = window.screen.height / window.screen.width
  const limit = window.screen.height === window.screen.availHeight ? 1.8 : 1.65

  return rate > limit // 全面屏true, 底部有固定元素
}

function formatPrice(price: number): number {
  return parseFloat((price / 100).toFixed(2))
}

// 根据地址打开谷歌地图
function addressMap(latitude: number, longitude: number): void {
  window.open(`https://maps.google.com?q=${latitude},${longitude}`, '_blank')
}

// 餐厅分类随机颜色
function categoriesRandomColor(): string {
  const color = ['#FDF0EA', '#D6F5E4', '#E5F6FE']

  return color[Math.floor(Math.random() * color.length)]
}

// 拨打电话
function callPhone(phone: string): void {
  window.open(`tel://${phone}`)
}

// 格式化超过1000的数字为xk，示例：1.8k
const formatThousand = (num: number): string => {
  return num > 999 ? `${(num / 1000).toFixed(1)}k` : `${num}`
}

// 输入框使用，0-9的数字，输入+、=、-等等字符时，将其删除
// isNeedSpace 是否可以包含空格
const formatInputNumber = (num: string, isNeedSpace = false): string => {
  const reg = isNeedSpace ? /^[0-9 ]*$/ : /^[0-9]*$/
  return !(reg.test(num)) ? num.substring(0, num.length - 1) : num
}

// 金额输入框，0-9的数字，可以输入. 但是只能是0.8 1.8   .只能有一个
const formatInputAmount = (num: string): string => {
  if (!(/^[0-9.]*$/.test(num))) {
    return num.substring(0, num.length - 1)
  }
  if (/^\.+[0-9]+$/.test(num)) { // 如果输入 .5  -> 0.5
    return `0.${num.replace(/\.+/, '')}`
  }
  if (/^[0-9]\.+[0-9]+$/.test(num)) { // 保留两位小数
    const curNum = num.replace(/\.+/, '.')
    const numDecimalPoint = curNum.split('.')[1].substr(0, 2)

    return `${curNum.split('.')[0]}.${numDecimalPoint}`
  }

  return num
}

export {
  formatPhone,
  formatAddress,
  formatDigit,
  formatNumber,
  validatePhone,
  validateEmail,
  validateNumber,
  validateZipCode,
  waitMoment,
  md5,
  generateSign,
  judgeScreenType,
  formatPrice,
  validateVerificationCode,
  addressMap,
  categoriesRandomColor,
  callPhone,
  validateCount,
  formatThousand,
  validateDot2Number,
  formatInputNumber,
  formatInputAmount
}
