import API from '@/network/API'
import { CustomSuccessData } from 'axios'
import UnclaimedGiftCardInfo from '../models/UnclaimedGiftCardInfo'

const _url = 'restaurantPay/v1.0'

async function unclaimedGiftCards(): Promise<CustomSuccessData<UnclaimedGiftCardInfo[]>> {
  return API.instance().get<UnclaimedGiftCardInfo[]>(`${_url}/app/giftcards/unclaimed`, undefined, { isSpin: false })
}

// 同意、拒绝赠送Gift Card
async function claimGiftCard(giveId: string, approved: boolean): Promise<CustomSuccessData<void>> {
  return API.instance().post<void>(`${_url}/app/giftcards/${giveId}/confirm`, { approved })
}

// 获取他人赠送待领取Gift Card信息（通过链接）
async function acceptOrRefuseGiftCard(giveId: string, approved: boolean): Promise<CustomSuccessData<void>> {
  return API.instance().post<void>(`${_url}/public/giftcards/${giveId}/confirm`, { approved }, { isAuthorization: false })
}

export default {
  unclaimedGiftCards,
  claimGiftCard,
  acceptOrRefuseGiftCard
}
