// import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { createMemoryHistory, createRouter, RouteRecordRaw } from 'vue-router'
import { trackRouter } from 'vue-gtag-next'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/views/home/index.vue')
  },
  // {
  //   path: '/demo',
  //   name: 'Demo',
  //   component: () => import(/* webpackChunkName: "demo" */ '../components/common/demo.vue')
  // },
  {
    path: '/enroll-waitlist',
    name: 'enrollWaitlist',
    component: () =>
      import(
        /* webpackChunkName: "enroll-waitlist" */ '@/views/enroll-waitlist/index.vue'
      )
  },
  {
    path: '/favorite-shops',
    name: 'favoriteShops',
    component: () =>
      import(
        /* webpackChunkName: "favorite-shops" */ '@/views/favorite-shops/index.vue'
      )
  },
  {
    path: '/points',
    name: 'points',
    component: () =>
      import(/* webpackChunkName: "points" */ '@/views/points/index.vue')
  },
  {
    path: '/my-coupon',
    name: 'myCoupon',
    component: () =>
      import(/* webpackChunkName: "my-coupon" */ '@/views/my-coupon/index.vue')
  },
  {
    path: '/coupon-detail',
    name: 'couponDetail',
    component: () =>
      import(
        /* webpackChunkName: "coupon-detail" */ '@/views/coupon-detail/index.vue'
      )
  },
  {
    path: '/promotion-detail',
    name: 'promotionDetail',
    component: () =>
      import(
        /* webpackChunkName: "promotion-detial" */ '@/views/promotion-detail/index.vue'
      )
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/login/index.vue')
  },
  {
    path: '/new-order',
    name: 'newOrder',
    component: () =>
      import(
        /* webpackChunkName: "new-order-type" */ '@/views/new-order/index.vue'
      )
  },
  {
    path: '/change-guest-count',
    name: 'changeGuestCount',
    component: () =>
      import(
        /* webpackChunkName: "change-guest-count" */ '@/views/change-guest-count/index.vue'
      )
  },
  {
    path: '/order-history',
    name: 'orderHistory',
    component: () =>
      import(
        /* webpackChunkName: "order-history" */ '@/views/order-history/index.vue'
      )
  },
  {
    path: '/order-information',
    name: 'orderInformation',
    component: () =>
      import(
        /* webpackChunkName: "order-information" */ '@/views/order-information/index.vue'
      )
  },
  {
    path: '/order-review',
    name: 'orderReview',
    component: () =>
      import(
        /* webpackChunkName: "order-review" */ '@/views/order-review/index.vue'
      )
  },
  {
    path: '/order-review-information',
    name: 'orderReviewInformation',
    component: () =>
      import(
        /* webpackChunkName: "order-review-information" */ '@/views/order-review-information/index.vue'
      )
  },
  {
    path: '/online-order-review',
    name: 'onlineOrderReview',
    component: () =>
      import(
        /* webpackChunkName: "online-order-review" */ '@/views/online-order-review/index.vue'
      )
  },
  {
    path: '/online-order-delivery',
    name: 'onlineOrderDelivery',
    component: () =>
      import(
        /* webpackChunkName: "online-order-delivery" */ '@/views/online-order-delivery/index.vue'
      )
  },
  {
    path: '/online-order-notes',
    name: 'onlineOrderNotes',
    component: () =>
      import(
        /* webpackChunkName: "online-order-notes" */ '@/views/online-order-notes/index.vue'
      )
  },
  {
    path: '/pay-card',
    name: 'payCard',
    component: () =>
      import(/* webpackChunkName: "pay-card" */ '@/views/pay-card/index.vue')
  },
  {
    path: '/pay-for-giftcard',
    name: 'payForGiftcard',
    component: () =>
      import(
        /* webpackChunkName: "pay-for-giftcard" */ '@/views/pay-for-giftcard/index.vue'
      )
  },
  {
    path: '/pay-gift-card-remain',
    name: 'payGiftCardRemain',
    component: () =>
      import(
        /* webpackChunkName: "pay-gift-card-remain" */ '@/views/pay-giftcard-remain/index.vue'
      )
  },
  {
    path: '/pay-complete',
    name: 'payComplete',
    component: () =>
      import(
        /* webpackChunkName: "pay-complete" */ '@/views/pay-complete/index.vue'
      )
  },
  {
    path: '/pay-review',
    name: 'payReview',
    component: () =>
      import(
        /* webpackChunkName: "pay-review" */ '@/views/pay-review/index.vue'
      )
  },
  {
    path: '/pay-review-cash',
    name: 'payReviewCash',
    component: () =>
      import(
        /* webpackChunkName: "pay-review-cash" */ '@/views/pay-review-cash/index.vue'
      )
  },
  {
    path: '/pay-counter-review',
    name: 'payCounterReview',
    component: () =>
      import(
        /* webpackChunkName: "pay-counter-review" */ '@/views/pay-counter-review/index.vue'
      )
  },
  {
    path: '/pay-counter-verify',
    name: 'payCounterVerify',
    component: () =>
      import(
        /* webpackChunkName: "pay-counter-verify" */ '@/views/pay-counter-verify/index.vue'
      )
  },
  {
    path: '/pay-review-coupon',
    name: 'payReviewCoupon',
    component: () =>
      import(
        /* webpackChunkName: "pay-review-coupon" */ '@/views/pay-review-coupon/index.vue'
      )
  },
  {
    path: '/pay-review-points',
    name: 'payReviewPoints',
    component: () =>
      import(
        /* webpackChunkName: "pay-review-points" */ '@/views/pay-review-points/index.vue'
      )
  },
  {
    path: '/restaurant-information',
    name: 'restaurantInformation',
    component: () =>
      import(
        /* webpackChunkName: "restaurant-information" */ '@/views/restaurant-information/index.vue'
      )
  },
  {
    path: '/waitlist-information',
    name: 'waitlistInformation',
    component: () =>
      import(
        /* webpackChunkName: "waitlist-information" */ '@/views/waitlist-information/index.vue'
      )
  },
  {
    path: '/shop-order-menu',
    name: 'shopOrderMenu',
    component: () =>
      import(
        /* webpackChunkName: "shop-order-menu" */ '@/views/shop-order-menu/index.vue'
      )
  },
  {
    path: '/my-giftcard',
    name: 'myGiftcard',
    component: () =>
      import(
        /* webpackChunkName: "my-giftcard" */ '@/views/my-giftcard/index.vue'
      )
  },
  {
    path: '/giftcard-list',
    name: 'giftcardList',
    component: () =>
      import(
        /* webpackChunkName: "giftcard-list" */ '@/views/giftcard-list/index.vue'
      )
  },
  {
    path: '/giftcard-detail',
    name: 'giftcardDetail',
    component: () =>
      import(
        /* webpackChunkName: "giftcard-detail" */ '@/views/giftcard-detail/index.vue'
      )
  },
  {
    path: '/giftcard-record',
    name: 'giftcardRecord',
    component: () =>
      import(
        /* webpackChunkName: "giftcard-record" */ '@/views/giftcard-record/index.vue'
      )
  },
  {
    path: '/giftcard-buy',
    name: 'giftcardBuy',
    component: () =>
      import(
        /* webpackChunkName: "giftcard-buy" */ '@/views/giftcard-buy/index.vue'
      )
  },
  {
    path: '/giftcard-send',
    name: 'giftcardSend',
    component: () =>
      import(
        /* webpackChunkName: "giftcard-send" */ '@/views/giftcard-send/index.vue'
      )
  },
  {
    path: '/giftcard-send-confirm',
    name: 'giftcardSendConfirm',
    component: () =>
      import(
        /* webpackChunkName: "giftcard-send-confirm" */ '@/views/giftcard-send-confirm/index.vue'
      )
  },
  {
    path: '/giftcard-buy-result',
    name: 'giftcardBuyResult',
    component: () =>
      import(
        /* webpackChunkName: "giftcard-buy-result" */ '@/views/giftcard-buy-result/index.vue'
      )
  },
  {
    path: '/giftcard-claim',
    name: 'giftcardClaim',
    component: () =>
      import(
        /* webpackChunkName: "giftcard-claim" */ '@/views/giftcard-claim/index.vue'
      )
  },
  {
    path: '/user-center',
    name: 'userCenter',
    component: () =>
      import(
        /* webpackChunkName: "user-center" */ '@/views/user-center/index.vue'
      )
  },
  {
    path: '/edit-user-info',
    name: 'editUserInfo',
    component: () =>
      import(
        /* webpackChunkName: "edit-user-info" */ '@/views/edit-user-info/index.vue'
      )
  },
  {
    path: '/user-address',
    name: 'userAddress',
    component: () =>
      import(
        /* webpackChunkName: "user-address" */ '@/views/new-address/index.vue'
      )
  },
  {
    path: '/state-city',
    name: 'stateCity',
    component: () =>
      import(
        /* webpackChunkName: "state-city" */ '@/views/state-city/index.vue'
      )
  },
  {
    path: '/check-table-info',
    name: 'checkTableInfo',
    component: () =>
      import(
        /* webpackChunkName: "check-table-info" */ '@/views/check-table-info/index.vue'
      )
  },
  {
    path: '/check-order-info',
    name: 'checkOrderInfo',
    component: () =>
      import(
        /* webpackChunkName: 'check-order-info' */ '@/views/check-scan-to-pay-order/index.vue'
      )
  },
  {
    path: '/pick-table-order',
    name: 'pickTableOrder',
    component: () =>
      import(
        /* webpackChunkName: "pick-table-orders" */ '@/views/pick-table-order/index.vue'
      )
  },
  {
    path: '/quick-service-order',
    name: 'quickServiceOrder',
    component: () =>
      import(
        /* webpackChunkName: "quick-service-order" */ '@/views/quick-service-order/index.vue'
      )
  },
  {
    path: '/order-submitted',
    name: 'orderSubmitted',
    component: () =>
      import(
        /* webpackChunkName: "order-submitted" */ '@/views/order-submitted/index.vue'
      )
  },
  {
    path: '/share',
    name: 'share',
    component: () =>
      import(/* webpackChunkName: "share" */ '@/views/share/index.vue')
  },
  {
    path: '/share-get',
    name: 'shareGet',
    component: () =>
      import(/* webpackChunkName: "share-get" */ '@/views/share-get/index.vue')
  },
  {
    path: '/order-invoice-paid',
    name: 'orderInvoicePaid',
    component: () =>
      import(
        /* webpackChunkName: "order-invoice-paid" */ '@/views/order-invoice-paid/index.vue'
      )
  },
  {
    path: '/order-functions',
    name: 'orderFunctions',
    component: () =>
      import(
        /* webpackChunkName: "order-functions" */ '@/views/order-functions/index.vue'
      )
  },
  {
    path: '/waitlist-closed',
    name: 'waitlistClosed',
    component: () =>
      import(
        /* webpackChunkName: "not-waitlist" */ '@/views/waitlist-closed/index.vue'
      )
  },
  {
    path: '/waitlist-completed',
    name: 'waitlistCompleted',
    component: () =>
      import(
        /* webpackChunkName: "waitlist-completed" */ '@/views/waitlist-completed/index.vue'
      )
  },
  {
    path: '/membership',
    name: 'membership',
    component: () =>
      import(
        /* webpackChunkName: 'membership' */ '@/views/membership/index.vue'
      )
  },
  {
    path: '/my-membership',
    name: 'myMembership',
    component: () =>
      import(
        /* webpackChunkName: 'myMembership' */ '@/views/my-membership/index.vue'
      )
  },
  {
    path: '/membership-buy',
    name: 'membership-buy',
    component: () =>
      import(
        /* webpackChunkName: 'membership-buy' */ '@/views/membership-buy/index.vue'
      )
  },
  {
    path: '/pay-for-membership',
    name: 'payForMembership',
    component: () =>
      import(
        /* webpackChunkName: 'pay-for-membership' */ '@/views/pay-for-membership/index.vue'
      )
  },
  {
    path: '/membership-buy-result',
    name: 'membershipBuyResult',
    component: () =>
      import(
        /* webpackChunkName: 'membership-buy-result' */ '@/views/membership-buy-result/index.vue'
      )
  },
  {
    path: '/qrcode-scanner',
    name: 'qrcodeScanner',
    component: () =>
      import(
        /* webpackChunkName: 'qrcode-scanner' */ '@/views/qrcode-scanner/index.vue'
      )
  },
  {
    path: '/pickup-delivery-time',
    name: 'pickupDeliveryTime',
    component: () =>
      import(
        /* webpackChunkName: "pickup-delivery-time" */ '@/views/pickup-delivery-time/index.vue'
      )
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createMemoryHistory(),
  routes
})

const currentEnvironment = process.env.VUE_APP_ENV

if (currentEnvironment !== 'development' && currentEnvironment !== 'dev') {
  trackRouter(router)
}

export default router
