import Resolver from './Resolver'
import ResolveResult from './ResolveResult'
import QRCodeType from '../models/QRCodeType'
import { fetchQrCodeResult } from '../api'
import ResolveErrorType from '../models/ResolveErrorType'
import OrderInformationManager from '@/utils/order-information-manager'
import ShopInformationManager from '@/utils/shop-information-manager/ShopInformationManager'
// import { currentMenuId } from '@/utils/menu-manager/utils/menu-builder'
// import RestaurantInfoManager from '@/views/restaurant-information/utils/restaurant-info-manager'
import { buildSessionOnlineOrder } from '@/utils/order-information-manager/utils/build-session-order-model'
import OnlineOrderModeType from '@/utils/shop-information-manager/models/OnlineOrderModeType'
// import { currentMenuId } from '@/utils/menu-manager/utils/menu-builder'
import RestaurantInfoManager from '@/views/restaurant-information/utils/restaurant-info-manager'

/**
 * 在线订单路径解析器，处理在线订单二维码的解析与验证
 *
 * @class OnlineOrderPathResolver
 * @extends Resolver
 * @description
 * - 处理类型为QRCodeType.OnlineOrder的二维码
 * - 验证店铺是否启用在线订单功能
 * - 根据店铺设置的在线订单模式返回不同的页面路径
 * - 创建会话在线订单信息
 */
export default class OnlineOrderPathResolver extends Resolver {
  /**
   * 执行在线订单路径解析逻辑
   *
   * @returns {Promise<ResolveResult>} 路径解析结果
   * @description
   * - 验证必要参数: type(t), shopId(rid)和code
   * - 检查type是否为在线订单类型(QRCodeType.OnlineOrder)
   * - 验证店铺是否启用在线订单功能
   * - 验证二维码是否有效
   * - 根据店铺的在线订单模式设置不同的跳转路径
   * - 创建会话在线订单信息
   *
   * @throws {Error} 当店铺未启用在线订单或二维码无效时抛出错误
   */
  async resolvePath(): Promise<ResolveResult> {
    if (this._pathQuery !== undefined) {
      const { t, rid: shopId, code } = this._pathQuery

      if (t !== undefined && shopId !== undefined && code !== undefined) {
        const orderType = parseInt(t)
        if (orderType !== QRCodeType.OnlineOrder) {
          return Promise.resolve({ isResolved: false })
        } else {
          try {
            const shopInformation = await ShopInformationManager.instance().fetchShopInformation(
              shopId
            )
            if (!shopInformation.posModule.isOnlineOrderingEnabled) {
              return Promise.reject(new Error('onlineOrderNotEnabled'))
            }
            if (
              !shopInformation.onlineOrderingSetting.isOnlineOrderingEnabled
            ) {
              return Promise.reject(new Error('posOnlineOrderNotEnabled'))
            }

            // const menuId = await currentMenuId(shopId, true)
            // if (menuId === undefined) {
            //   RestaurantInfoManager.instance().setParams(shopId, '1')
            //   return Promise.resolve({
            //     isResolved: true,
            //     pathName: 'restaurantInformation'
            //   })
            // }
            const { data } = await fetchQrCodeResult(code)
            const { isEnabled } = data

            if (isEnabled) {
              OrderInformationManager.instance().setSessionOnlineOrder(
                buildSessionOnlineOrder(shopId, undefined, true)
              )
              // return {
              //   isResolved: true,
              //   pathName: 'shopOrderMenu'
              // }

              if (
                shopInformation.onlineOrderingSetting.onlineOrderMode ===
                OnlineOrderModeType.PlaceOrder
              ) {
                return {
                  isResolved: true,
                  pathName: 'pickupDeliveryTime'
                }
              }

              RestaurantInfoManager.instance().setParams(shopId)
              return {
                isResolved: true,
                pathName: 'restaurantInformation'
              }
            }
            return Promise.reject(
              new Error(
                JSON.stringify({
                  type: QRCodeType.OnlineOrder,
                  error: ResolveErrorType.QRCodeNotEnabled
                })
              )
            )
          } catch (e) {
            return Promise.reject(e)
          }
        }
      }
    }

    return Promise.resolve({ isResolved: false })
  }
}
