import Resolver from '@/views/app/utils/path-resolver/resolvers/Resolver'
import ResolveResult from '@/views/app/utils/path-resolver/resolvers/ResolveResult'
import QRCodeType from '@/views/app/utils/path-resolver/models/QRCodeType'
import WaitlistNodeBuilder from '@/firebase/firebase-node-builder/WaitlistNodeBuilder'
import once from '@/utils/helper/once'
import WaitlistInfoManager from '@/utils/waitlist-info-manager'

export default class WaitlistDetailPathResolver extends Resolver {
  async resolvePath(): Promise<ResolveResult> {
    if (this._pathQuery !== undefined) {
      const { t: queryType, rid: shopId, aid: autoId } = this._pathQuery

      if (queryType !== undefined && shopId !== undefined && autoId !== undefined && parseInt(queryType) === QRCodeType.WaitlistDetail) {
        const waitlistCreateTimeNode = new WaitlistNodeBuilder(shopId, autoId).createTime()
        const createTime = await once(waitlistCreateTimeNode)
        
        WaitlistInfoManager.instance().updateShopIdAutoId({ shopId, autoId })
        if (createTime.val() !== null) {
          return Promise.resolve({ isResolved: true, pathName: 'waitlistInformation' })
        } else {
          // return Promise.resolve({ isResolved: true, pathName: 'waitlistClosed' })
          return Promise.resolve({ isResolved: true, pathName: 'waitlistCompleted' })
        }
      }
    }

    return Promise.resolve({
      isResolved: false
    })
  }
}
