import Resolver from './Resolver'
import ResolveResult from './ResolveResult'
import { userShareInfo } from '@/views/app/utils/path-resolver/api'
import ShareGuestType from '@/views/share-get/models/ShareGuestType'
import { CouponError } from '@/network/error-code'

/**
 * 分享链接路径解析器，处理用户分享链接的解析与验证
 *
 * @class SharePathResolver
 * @extends Resolver
 * @description
 * - 根据分享代码(shareCode)获取分享信息
 * - 处理新用户和老用户的不同分享奖励逻辑
 * - 验证分享链接的有效性和过期状态
 * - 处理API错误并转换为对应的错误状态码
 */
export default class SharePathResolver extends Resolver {
  /**
   * 执行分享链接路径解析逻辑
   *
   * @returns {Promise<ResolveResult>} 路径解析结果
   * @description
   * - 验证必须参数: shareCode
   * - 调用用户分享信息API获取详细信息
   * - 根据用户类型(新用户/老用户)返回不同的处理结果
   * - 成功时返回分享获取页面路径和参数
   * - 处理API错误并转换为对应的错误状态码
   *
   * @throws {Error} 当分享链接无效或过期时抛出相应错误
   */
  async resolvePath(): Promise<ResolveResult> {
    const { shareCode } = this._pathQuery || {}

    if (shareCode !== undefined) {
      try {
        const code = decodeURIComponent(shareCode)
        const { data } = await userShareInfo(code)
        const { newGuestSharedInfo, oldGuestSharedInfo, shareGuestType } = data

        if (!newGuestSharedInfo && !oldGuestSharedInfo) {
          if (shareGuestType === ShareGuestType.NewCustomer) {
            return Promise.reject(new Error('couponAvailableToReturn'))
          } else if (shareGuestType === ShareGuestType.ReturnedCustomer) {
            return Promise.reject(new Error('couponAvailableToNew'))
          }
        }

        return Promise.resolve({ isResolved: true, pathName: 'shareGet', params: { shareInfo: JSON.stringify(data), shareCode: code } })
      } catch (error) {
        return Promise.reject(new Error((error as { code: number }).code === CouponError.ShareLinkExpired ? 'shareLinkExpired'
          : ((error as { code: number }).code === CouponError.ShareLinkInvalid ? 'shareLinkInvalid' : 'requestFail')))
      }
    }

    return Promise.resolve({ isResolved: false })
  }
}
