import API from '@/network/API'
import { CustomSuccessData } from 'axios'
import UserMenu from '../models/user-menu/UserMenu'

const _baseUrl = '/restaurants/v1.0'

function _userMenu (shopId: string): string {
  return `${_baseUrl}/${shopId}/userMenu`
}

async function fetchUserMenu (shopId: string): Promise<CustomSuccessData<UserMenu>> {
  return API.instance().get<UserMenu>(_userMenu(shopId), undefined, {
    isAuthorization: false,
    isSpin: true
  })
}

export { fetchUserMenu }
