import SessionOrderModel from './models/SessionOrderModel'
import SessionMenuOrder from './models/SessionMenuOrder'
import SessionDineInModel from './models/SessionDineInModel'
import SessionOnlineModel from './models/SessionOnlineModel'
import SessionTableFastModel from './models/SessionTableFastModel'
import SessionViewMenuOrder from './models/SessionViewMenuOrder'
import SessionWaitlistMenuModel from './models/SessionWaitlistMenuModel'
import SessionShareMenuModel from './models/SessionShareMenuModel'
import SessionNoTableFastModel from './models/SessionNoTableFastModel'
import SessionScanToPayModel from './models/SessionScanToPayModel'
/**
 * 订单信息管理器类，用于管理订单会话模型
 */
export default class OrderInformationManager {
  private static _orderInformationManager: OrderInformationManager

  /**
   * 获取订单信息管理器的单例实例
   * @returns {OrderInformationManager} 订单信息管理器实例
   */
  static instance(): OrderInformationManager {
    if (this._orderInformationManager === undefined) {
      this._orderInformationManager = new OrderInformationManager()
    }

    return this._orderInformationManager
  }

  private _sessionOrderModel: SessionOrderModel | undefined

  private constructor() {
    this._sessionOrderModel = undefined
  }

  /**
   * 获取当前会话的订单模型
   * @returns {SessionOrderModel | undefined} 当前会话的订单模型
   */
  get sessionOrderModel(): SessionOrderModel | undefined {
    return this._sessionOrderModel
  }

  /**
   * 移除当前会话的订单模型
   */
  removeSessionOrderModel(): void {
    this._sessionOrderModel = undefined
  }

  /**
   * 设置堂食订单模型
   * @param {SessionDineInModel} model - 堂食订单模型
   */
  setSessionDineInOrder(model: SessionDineInModel): void {
    this._sessionOrderModel = model
  }

  /**
   * 设置菜单订单模型
   * @param {SessionMenuOrder} model - 菜单订单模型
   */
  setSessionMenuOrder(model: SessionMenuOrder): void {
    this._sessionOrderModel = model
  }

  /**
   * 设置无桌快速订单模型
   * @param {SessionNoTableFastModel} model - 无桌快速订单模型
   */
  setSessionNoTableFastOrder(model: SessionNoTableFastModel): void {
    this._sessionOrderModel = model
  }

  /**
   * 设置在线订单模型
   * @param {SessionOnlineModel} model - 在线订单模型
   */
  setSessionOnlineOrder(model: SessionOnlineModel): void {
    this._sessionOrderModel = model
  }

  /**
   * 设置快速订单模型
   * @param {SessionTableFastModel} model - 快速订单模型
   */
  setSessionTableFastOrder(model: SessionTableFastModel): void {
    this._sessionOrderModel = model
  }

  /**
   * 设置查看菜单订单模型
   * @param {SessionViewMenuOrder} model - 查看菜单订单模型
   */
  setSessionViewMenuOrder(model: SessionViewMenuOrder): void {
    this._sessionOrderModel = model
  }

  /**
   * 设置等待列表订单模型
   * @param {SessionWaitlistMenuModel} model - 等待列表订单模型
   */
  setSessionWaitlistOrder(model: SessionWaitlistMenuModel): void {
    this._sessionOrderModel = model
  }

  /**
   * 设置共享菜单订单模型
   * @param {SessionShareMenuModel} model - 共享菜单订单模型
   */
  setSessionShareMenuOrder(model: SessionShareMenuModel): void {
    this._sessionOrderModel = model
  }

  /**
   * 设置扫码支付模型
   * @param {SessionScanToPayModel} model - 扫码支付模型
   */
  setSessionScanToPayModel(model: SessionScanToPayModel): void {
    this._sessionOrderModel = model
  }

  /**
   * 设置会话订单模型
   * @param {SessionOrderModel} model - 会话订单模型
   */
  setSessionOrderModel(model: SessionOrderModel): void {
    this._sessionOrderModel = model
  }
}
