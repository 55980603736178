import Keys from '../local-storage-manager/Keys'
import ChannelCache from './channel-cache'

/**
 * 渠道枚举
 */
export enum ChannelType {
  UNKNOWN = 0,
  YAMMII = 1,
  FORTUNE_POS = 2,
  CIRCLE_EATS = 3,
  COWBOY_MEAL = 4
}

/**
 * 渠道管理类
 * 负责根据域名识别渠道，管理渠道信息的存储和获取，以及存储当前用户使用的店铺渠道信息
 */
export default class ChannelManager {
  private static _manager: ChannelManager
  private channelCache: ChannelCache
  // 存储店铺ID与其可用渠道的映射关系
  private shopChannelsMap: Map<string, number[]>

  constructor() {
    this.channelCache = ChannelCache.instance()
    this.shopChannelsMap = new Map<string, number[]>()
  }

  static instance(): ChannelManager {
    if (this._manager === undefined) {
      this._manager = new ChannelManager()
    }
    return this._manager
  }

  /**
   * 获取渠道类型
   * @returns 渠道类型值
   */
  getChannel(): ChannelType {
    const channelValue = localStorage.getItem(Keys.CHANNEL)
    return channelValue
      ? (Number(channelValue) as ChannelType)
      : ChannelType.UNKNOWN
  }

  /**
   * 设置渠道类型
   * @param channel 渠道类型值
   */
  setChannel(channel: ChannelType): void {
    localStorage.setItem(Keys.CHANNEL, channel.toString())
  }

  /**
   * 移除渠道信息
   */
  removeChannel(): void {
    localStorage.removeItem(Keys.CHANNEL)
    this.channelCache.clearCache()
  }

  /**
   * 设置店铺的渠道信息
   * @param shopId 店铺ID
   * @param channels 店铺可用的渠道列表
   */
  setShopChannels(shopId: string, channels: number[]): void {
    this.shopChannelsMap.set(shopId, channels)
  }

  /**
   * 获取店铺的渠道信息
   * @param shopId 店铺ID
   * @returns 店铺可用的渠道列表，如果没有则返回空数组
   */
  getShopChannels(shopId: string): number[] {
    return this.shopChannelsMap.get(shopId) || []
  }

  /**
   * 判断店铺是否包含指定渠道
   * @param shopId 店铺ID
   * @param channel 渠道类型
   * @returns 是否包含该渠道
   */
  hasShopChannel(shopId: string, channel: ChannelType): boolean {
    const channels = this.getShopChannels(shopId)
    return channels.includes(channel)
  }

  /**
   * 清除店铺渠道信息
   * @param shopId 可选的店铺ID，如果不提供则清除所有店铺的渠道信息
   */
  clearShopChannels(shopId?: string): void {
    if (shopId) {
      this.shopChannelsMap.delete(shopId)
    } else {
      this.shopChannelsMap.clear()
    }
  }

  /**
   * 根据域名判断渠道类型
   * @returns Promise<ChannelType>
   */
  async identifyChannelByDomain(): Promise<ChannelType> {
    const host = window.location.hostname.toLowerCase()
    const channels = await this.channelCache.getChannels()

    // 先尝试从API获取的渠道信息中匹配
    const matchedChannel = channels.find((ch) =>
      host.includes(ch.domain.toLowerCase())
    )

    if (matchedChannel) {
      return matchedChannel.channel as ChannelType
    }

    // 如果API中没有匹配的渠道，使用默认的匹配逻辑
    switch (true) {
      case host.includes('yammii.com'):
      case host.includes('yammii.net'):
        return ChannelType.YAMMII
      case host.includes('fortunepos.com'):
      case host.includes('fortunepos.net'):
        return ChannelType.FORTUNE_POS
      case host.includes('localhost'):
      case host.includes('circle-eats.com'):
      case host.includes('circle-eats.net'):
        return ChannelType.CIRCLE_EATS
      case host.includes('cowboypos.com'):
      case host.includes('cowboypos.net'):
        return ChannelType.COWBOY_MEAL
      default:
        return ChannelType.UNKNOWN
    }
  }

  /**
   * 初始化渠道信息
   * 在用户进入系统时调用，识别并存储渠道信息
   * @returns Promise<ChannelType>
   */
  async initializeChannel(): Promise<ChannelType> {
    const channel = await this.identifyChannelByDomain()
    this.setChannel(channel)
    console.log('initializeChannel', channel)
    return channel
  }

  /**
   * 获取渠道的字符串表示
   * @param channel 渠道类型
   * @returns 渠道字符串标识
   */
  getChannelString(channel: ChannelType = this.getChannel()): string {
    switch (channel) {
      case ChannelType.YAMMII:
        return 'yammii'
      case ChannelType.FORTUNE_POS:
        return 'fortunepos'
      case ChannelType.CIRCLE_EATS:
        return 'circle-eats'
      case ChannelType.COWBOY_MEAL:
        return 'cowboymeal'
      default:
        return ''
    }
  }

  /**
   * 获取渠道的显示名称
   * @param channel 渠道类型
   * @returns 渠道显示名称
   */
  getChannelDisplayName(channel: ChannelType = this.getChannel()): string {
    switch (channel) {
      case ChannelType.YAMMII:
        return 'Yammii'
      case ChannelType.FORTUNE_POS:
        return 'FortunePOS'
      case ChannelType.CIRCLE_EATS:
        return 'Circle Eats'
      case ChannelType.COWBOY_MEAL:
        return 'Cowboy'
      default:
        return ''
    }
  }
}
