export default class WaitlistInfoManager {
  private static _manager: WaitlistInfoManager

  static instance(): WaitlistInfoManager {
    if (this._manager === undefined) {
      this._manager = new WaitlistInfoManager()
    }

    return this._manager
  }

  private _shopId: string
  private _autoId: string

  private constructor() {
    this._shopId = ''
    this._autoId = ''
  }

  get shopId(): string {
    return this._shopId
  }

  get autoId(): string {
    return this._autoId
  }

  updateShopIdAutoId({ shopId, autoId }: { shopId: string, autoId: string }): void {
    this._shopId = shopId
    this._autoId = autoId
  }

  reset(): void {
    this._shopId = ''
    this._autoId = ''
  }
}
