import API from '@/network/API'
import { CustomSuccessData } from 'axios'
import { ChannelDto } from './models/ChannelDto'

const url = '/restaurants/v1.0/public/restaurant/channels'

/**
 * 获取渠道和域名对应关系
 * @returns Promise<CustomSuccessData<ChannelDto[]>>
 */
export function getChannels(): Promise<CustomSuccessData<ChannelDto[]>> {
  return API.instance().get<ChannelDto[]>(url, {}, { isAuthorization: false })
}
