enum UserOrderType {
  ServerDineInOrder,
  UserDineInOrder,
  NewDineInOrder,
  UserFastOrder,
  UserOnlineOrder
}

interface TableOrder {
  autoId: string
  hasSubmit: boolean
  itemNum: string
  numberOfGuest: number
  openTime: string
  orderNumber: string
  orderSerialNumber: string
  subTotal: number
  userId: string
}

interface DineInOrderTableCheckInfo {
  isNoOrders: boolean
  orders?: TableOrder[]
}

export { DineInOrderTableCheckInfo, UserOrderType, TableOrder }
