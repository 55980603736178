import Resolver from './Resolver'
import ResolveResult from './ResolveResult'
import QRCodeType from '../models/QRCodeType'
import { fetchPcOnlineOrder } from '../api'
import OrderInformationManager from '@/utils/order-information-manager'
import OrderMenuType from '@/utils/order-information-manager/models/OrderMenuType'
import OrderMenuPreOrderType from '@/utils/order-information-manager/models/OrderMenuPreOrderType'
import LocalOrderedItemDataSource from '@/utils/ordered-item-manager/data-source/LocalOrderedItemDataSource'
import OrderedItemManager from '@/utils/ordered-item-manager'

/**
 * PC端在线订单路径解析器，处理PC端生成的在线订单二维码
 *
 * @class PcOnlineOrderPathResolver
 * @extends Resolver
 * @description
 * - 处理类型为QRCodeType.PCOnlineOrder的二维码
 * - 从服务器获取PC端创建的在线订单信息
 * - 初始化本地订单项数据源
 * - 创建会话在线订单并跳转到订单审核页面
 */
export default class PcOnlineOrderPathResolver extends Resolver {
  /**
   * 执行PC端在线订单路径解析逻辑
   *
   * @returns {Promise<ResolveResult>} 路径解析结果
   * @description
   * - 验证必要参数: type(t)和code
   * - 检查type是否为PC端在线订单类型(QRCodeType.PCOnlineOrder)
   * - 通过code从服务器获取PC端创建的订单详情
   * - 初始化本地订单项数据源和管理器
   * - 设置在线配送信息，处理可能缺失的配送信息
   * - 创建会话在线订单，包含餐具数量、备注、用户信息等
   * - 成功时跳转到在线订单审核页面
   *
   * @throws {Error} 当二维码过期或服务器请求失败时抛出错误
   */
  async resolvePath(): Promise<ResolveResult> {
    if (this._pathQuery !== undefined) {
      const { t, code } = this._pathQuery

      if (t !== undefined && code !== undefined) {
        const orderType = parseInt(t)
        if (orderType !== QRCodeType.PCOnlineOrder) {
          return Promise.resolve({ isResolved: false })
        } else {
          try {
            const { data } = await fetchPcOnlineOrder(code)
            const { onlineOrder, restaurantId: shopId } = data
            const { autoId, flatwareCount, note, onlineUser } = onlineOrder
            // 菜品初始化
            const localOrderedItemDataSource = LocalOrderedItemDataSource.instance()
            new OrderedItemManager(localOrderedItemDataSource)
            localOrderedItemDataSource.initialItems(
              onlineOrder.submission.items
            )

            const onlineDelivery =
              onlineOrder.onlineDelivery !== undefined
                ? onlineOrder.onlineDelivery
                : {
                    deliverFee: 0,
                    distance: 0,
                    fromCity: '',
                    fromState: '',
                    fromStreet: '',
                    fromZipcode: '',
                    isPickup: true,
                    scheduleTime: '',
                    scheduleTimestamp: 0,
                    toCity: '',
                    toState: '',
                    toStreet: '',
                    toZipcode: '',
                    expires: 0,
                    deliveryType: 1
                  }

            OrderInformationManager.instance().setSessionOnlineOrder({
              shopId,
              preOrder: OrderMenuPreOrderType.NoPreOrder,
              orderType: OrderMenuType.Online,
              isShowBack: true,
              autoId,
              flatwareCount,
              note,
              onlineDelivery,
              onlineUser,
              isPaid: false,
              guestCount: 1
            })

            return {
              isResolved: true,
              pathName: 'onlineOrderReview'
            }
          } catch (error) {
            if (
              `${(error as { message: string }).message}`.indexOf('50046') !==
              -1
            ) {
              return Promise.reject(new Error('qrCodePayExpired'))
            } else {
              return Promise.reject(error)
            }
          }
        }
      }
    }

    return Promise.resolve({ isResolved: false })
  }
}
