import Resolver from './Resolver'
import ResolveResult from './ResolveResult'
import QRCodeType from '../models/QRCodeType'
import { fetchQrCodeResult } from '@/views/app/utils/path-resolver/api'
import { fetchLocalWaitlist } from '../local-firebase-storage/waitlist'
import ShopAutoId from '@/utils/firebase-storage-manager/models/ShopAutoId'
import LocalStorageManager from '@/utils/local-storage-manager'
import ShopInformationManager from '@/utils/shop-information-manager/ShopInformationManager'
import { currentMenuId } from '@/utils/menu-manager/utils/menu-builder'
import RestaurantInfoManager from '@/views/restaurant-information/utils/restaurant-info-manager'
import ResolveErrorType from '@/views/app/utils/path-resolver/models/ResolveErrorType'
// import TimeManager from '@/network/utils/TimeManager'
import WaitlistInfoManager from '@/utils/waitlist-info-manager'
import UserNodeBuilder from '@/firebase/firebase-node-builder/UserNodeBuilder'
import WaitlistNodeBuilder from '@/firebase/firebase-node-builder/WaitlistNodeBuilder'
import once from '@/utils/helper/once'

/**
 * 等待列表路径解析器，处理等待列表二维码的解析与验证
 *
 * @class WaitlistPathResolver
 * @extends Resolver
 * @description
 * - 处理类型为QRCodeType.Waitlist的二维码
 * - 验证店铺是否启用等待列表功能
 * - 检查用户是否已在等待列表中
 * - 根据用户状态返回不同的页面路径
 */
export default class WaitlistPathResolver extends Resolver {
  /**
   * 从Firebase获取用户的等待列表自动ID
   *
   * @param {string} userId - 用户ID
   * @param {string} restaurantId - 餐厅ID
   * @returns {Promise<ShopAutoId | undefined>} 店铺自动ID对象或undefined
   * @description
   * - 查询用户的等待列表节点
   * - 遍历所有等待列表条目找到匹配的餐厅ID
   * - 返回匹配的自动ID和餐厅ID
   */
  async _fetchFirebaseWaitlistAutoId(userId: string, restaurantId: string): Promise<ShopAutoId | undefined> {
    const waitlistNode = new UserNodeBuilder(userId).waitlists()
    try {
      const waitlistsDataSnapshot = await once(waitlistNode)
      const waitlistsData = waitlistsDataSnapshot.val()

      if (waitlistsData !== null) {
        for (const autoId in waitlistsData) {
          const shopId = waitlistsData[autoId] as string

          if (shopId === restaurantId) {
            return Promise.resolve({ autoId, restaurantId: shopId })
          }
        }
      }

      return Promise.resolve(undefined)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  /**
   * 执行等待列表路径解析逻辑
   *
   * @returns {Promise<ResolveResult>} 路径解析结果
   * @description
   * - 验证必要参数: type(t), shopId(rid)和code
   * - 检查type是否为等待列表类型(QRCodeType.Waitlist)
   * - 验证店铺是否启用等待列表功能
   * - 验证二维码是否有效
   * - 检查用户是否已在等待列表中
   * - 根据用户状态返回不同的页面路径:
   *   - 已在列表且有创建时间: 等待列表信息页
   *   - 已在列表但无创建时间: 等待列表完成页
   *   - 未在列表: 注册等待列表页
   *
   * @throws {Error} 当店铺未启用等待列表或二维码无效时抛出错误
   */
  async resolvePath(): Promise<ResolveResult> {
    if (this._pathQuery !== undefined) {
      // const { t: queryType, rid: shopId, code, ts: timestamp, autoId } = this._pathQuery
      const { t: queryType, rid: shopId, code, autoId } = this._pathQuery

      if (queryType !== undefined && shopId !== undefined && code !== undefined) {
        if (parseInt(queryType) !== QRCodeType.Waitlist) {
          return Promise.resolve({ isResolved: false })
        } else {
          // if (timestamp !== undefined && TimeManager.instance().currentMSTimestamp >= parseInt(timestamp) + 7200000) {
          //   return Promise.reject(new Error('codeExpired'))
          // }
          try {
            const shopInformation = await ShopInformationManager.instance().fetchShopInformation(shopId)
            if (!shopInformation.posModule.isMobileWaitlistEnabled) {
              return Promise.reject(new Error('waitlistNotEnabled'))
            }
            if (!shopInformation.waitlistSetting.isWaitlistEnabled) {
              return Promise.reject(new Error('posWaitlistNotEnabled'))
            }

            const { data } = await fetchQrCodeResult(code)

            if (data.isEnabled) {
              let waitlistAutoId: ShopAutoId | undefined

              if (autoId === undefined) {
                waitlistAutoId = fetchLocalWaitlist(shopId)

                if (waitlistAutoId === undefined) {
                  const userId = new LocalStorageManager().userId

                  if (userId !== '') {
                    waitlistAutoId = await this._fetchFirebaseWaitlistAutoId(userId, shopId)
                  }
                }
              } else {
                waitlistAutoId = { restaurantId: shopId, autoId }
              }

              if (waitlistAutoId !== undefined) {
                const waitlistCreateTimeNode = new WaitlistNodeBuilder(waitlistAutoId.restaurantId, waitlistAutoId.autoId).createTime()
                const createTime = await once(waitlistCreateTimeNode)
                WaitlistInfoManager.instance().updateShopIdAutoId({ shopId: waitlistAutoId.restaurantId, autoId: waitlistAutoId.autoId })
                if (createTime.val() !== null) {
                  return Promise.resolve({ isResolved: true, pathName: 'waitlistInformation' })
                } else {
                  return Promise.resolve({ isResolved: true, pathName: 'waitlistCompleted' })
                }
              } else {
                const menuId = await currentMenuId(shopId, false)
                if (menuId === undefined) {
                  RestaurantInfoManager.instance().setParams(shopId, '1')
                  return Promise.resolve({
                    isResolved: true,
                    pathName: 'restaurantInformation'
                  })
                }
                return Promise.resolve({ isResolved: true, pathName: 'enrollWaitlist', params: { shopId } })
              }
            } else {
              return Promise.reject(new Error(JSON.stringify({
                type: QRCodeType.Waitlist,
                error: ResolveErrorType.QRCodeNotEnabled
              })))
            }
          } catch (error) {
            return Promise.reject(error)
          }
        }
      }
    }

    return Promise.resolve({ isResolved: false })
  }
}
