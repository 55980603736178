/**
 * 解析当前URL的查询参数并转换为键值对对象
 *
 * @example
 * // 当前URL为 http://example.com?name=John&age=25
 * const params = buildQueryParams();
 * // 返回 { name: "John", age: "25" }
 *
 * @returns {Object.<string, string>} 包含所有查询参数的对象，键为参数名，值为参数值（字符串类型）
 * @description
 * - 自动过滤格式不正确的参数项（缺少键或值的参数）
 * - 保留原始参数值的字符串形式，不会进行类型转换
 * - 不会对参数值进行URI解码（保留原始编码状态）
 */
export default function buildQueryParams(): { [key: string]: string } {
  const queryStr = window.location.search.substring(1)
  const queries = queryStr.split('&')
  const result: { [key: string]: string } = {}
  queries.forEach(query => {
    const keyValue = query.split('=')
    const key = keyValue[0]
    const value = keyValue[1]
    if (key !== undefined && value !== undefined) {
      result[key] = value
    }
  })
  return result
}
