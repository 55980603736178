export default class Keys {
  static readonly ACCESS_TOKEN = 'accessToken'
  static readonly REFRESH_TOKEN = 'refreshToken'
  static readonly EXPIRES = 'expires'
  static readonly USER_ID = 'userId'
  static readonly USER_DINE_IN_ORDERS = 'userDineInOrders'
  static readonly USER_WAITLISTS = 'userWaitlists'
  static readonly USER_FAST_ORDERS = 'userFastOrders'
  static readonly USER_ONLINE_ORDERS = 'userOnlineOrders'
  static readonly USER_NAME_PHONE = 'userNamePhone'
  static readonly CHANNEL = 'channel'
  /** 渠道缓存key */
  static readonly CHANNEL_CACHE = 'CHANNEL_CACHE'
}
