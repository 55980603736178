import API from '@/network/API'
import { CustomSuccessData } from 'axios'
import TriedItem from '../models/TriedItem'

const _url = '/restaurantOrders/v1.0/orders'

function _dish (shopId: string): string {
  return `${_url}/${shopId}/user/dish`
}

async function fetchUserItems (shopId: string): Promise<CustomSuccessData<TriedItem[]>> {
  return API.instance().get<TriedItem[]>(_dish(shopId))
}

export { fetchUserItems }
