import Resolver from './Resolver'
import ResolveResult from './ResolveResult'
import QRCodeType from '../models/QRCodeType'
import LocalStorageManager from '@/utils/local-storage-manager'
import ShopAutoId from '@/utils/firebase-storage-manager/models/ShopAutoId'
import DineInOrderCheckModel from '@/views/app/utils/path-resolver/models/DineInOrderCheckModel'
import OrderInformationManager from '@/utils/order-information-manager'
import DineInOrToGo from '@/utils/pay-utils/models/order-models/DineInOrToGo'
import { isDineInOrderProOrder } from '@/utils/dine-in-order-pre-order'
import OrderSubmission from '@/views/order-information/models/OrderSubmission'
import DineInOrderNodeBuilder from '@/firebase/firebase-node-builder/DineInOrderNodeBuilder'
import UserNodeBuilder from '@/firebase/firebase-node-builder/UserNodeBuilder'
import { scanPayOrder } from '@/views/check-scan-to-pay-order/api'
import once from '@/utils/helper/once'
import { buildSessionDineOrder } from '@/utils/order-information-manager/utils/build-session-order-model'

/**
 * 扫码支付路径解析器，处理扫码支付二维码的解析与验证
 *
 * @class ScanPayResolver
 * @extends Resolver
 * @description
 * - 处理类型为QRCodeType.ScanPay的二维码
 * - 支持堂食订单的扫码支付处理
 * - 检查用户是否已有相关订单并处理不同状态
 * - 提供订单信息获取和支付状态验证
 */
export default class ScanPayResolver extends Resolver {
  /**
   * 检查指定店铺是否存在堂食订单
   *
   * @param {string} shopId - 店铺ID
   * @returns {Promise<{shopId: string, autoId: string} | undefined>} 店铺订单信息或undefined
   * @description
   * - 检查用户在指定店铺的堂食订单
   * - 对于登录用户，从Firebase中查询关联的订单
   * - 对于未登录用户，从本地存储中查询关联的订单
   * - 返回匹配的店铺ID和订单ID
   */
  private static async _isDineInOrderExisted(shopId: string): Promise<{ shopId: string, autoId: string } | undefined> {
    const localStorageManager = new LocalStorageManager()
    const userId = localStorageManager.userId

    if (userId !== '') {
      const userDineInOrdersNode = new UserNodeBuilder(userId).dineInOrders()
      try {
        const dineInOrdersRaw = await once(userDineInOrdersNode)
        const dineInOrders = dineInOrdersRaw.val()

        if (dineInOrders !== null) {
          const firebaseDineInOrders = dineInOrders as { [autoId: string]: { restaurantId: string, isOrderOwner: boolean } }
          for (const autoId in firebaseDineInOrders) {
            const { restaurantId } = firebaseDineInOrders[autoId]

            if (restaurantId === shopId) {
              return Promise.resolve({
                shopId,
                autoId
              })
            }
          }
        }

        return Promise.resolve(undefined)
      } catch (e) {
        return Promise.reject(e)
      }
    } else {
      const dineInOrders: ShopAutoId[] = localStorageManager.userDineInOrders

      for (const dineInOrder of dineInOrders) {
        if (dineInOrder.restaurantId === shopId) {
          return Promise.resolve({
            shopId,
            autoId: dineInOrder.autoId
          })
        }
      }
      return Promise.resolve(undefined)
    }
  }

  /**
   * 获取订单详细信息
   *
   * @param {ShopAutoId} shopAutoId - 店铺自动ID对象
   * @returns {Promise<DineInOrderCheckModel | undefined>} 堂食订单检查模型或undefined
   * @description
   * - 从Firebase获取订单的详细信息
   * - 获取订单的桌台信息、用户数量、就餐方式等
   * - 检查订单是否为预订单
   * - 返回完整的订单检查模型
   */
  private async _fetchOrderInformation(shopAutoId: ShopAutoId): Promise<DineInOrderCheckModel | undefined> {
    try {
      const {
        restaurantId: shopId,
        autoId
      } = shopAutoId
      const dineInOrderNodeBuilder = new DineInOrderNodeBuilder(shopId, autoId)
      const tableInfoNode = dineInOrderNodeBuilder.tableInfo()
      const userCountNode = dineInOrderNodeBuilder.userCount()
      const dineInOrToGoNode = dineInOrderNodeBuilder.dineInOrToGo()
      const submissionsNode = dineInOrderNodeBuilder.submissions()
      const result = await Promise.all([
        once(tableInfoNode),
        once(userCountNode),
        once(dineInOrToGoNode),
        once(submissionsNode)
      ])
      const firebaseTableInfo = result[0].val()
      const firebaseUserCount = result[1].val()
      const firebaseDineInOrToGo = result[2].val()
      const firebaseSubmissions = result[3].val()

      if (firebaseTableInfo !== null && firebaseUserCount !== null) {
        const tableInfo = firebaseTableInfo as { tableGratuities: number, tableId: string, tableName: string }
        const userCount = firebaseUserCount as number
        const isDineIn = (firebaseDineInOrToGo as number) === DineInOrToGo.dineIn
        const submissions = firebaseSubmissions === null ? [] : firebaseSubmissions as OrderSubmission[]
        const preOrder = isDineInOrderProOrder(submissions)
        return Promise.resolve({
          tableInfo,
          userCount,
          autoId,
          isDineIn,
          preOrder,
          isPaid: false
        })
      } else {
        return Promise.resolve(undefined)
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  /**
   * 检查订单是否已支付
   *
   * @param {string} shopId - 店铺ID
   * @param {string} autoId - 订单ID
   * @returns {Promise<boolean>} 是否已支付
   * @description
   * - 从Firebase获取订单的支付信息
   * - 检查用户支付记录是否存在
   * - 验证支付状态并返回结果
   */
  private static async _fetchOrderIsPaid(shopId: string, autoId: string): Promise<boolean> {
    const orderPaymentsNode = new DineInOrderNodeBuilder(shopId, autoId).orderPayments()
    try {
      const orderPayment = await once(orderPaymentsNode)
      if (orderPayment.val() !== null) {
        const orderPaymentValue = orderPayment.val()
        if (orderPaymentValue !== null) {
          const guestPayments = orderPaymentValue[0].guestPayments
          if (guestPayments[0] !== undefined) {
            const isPaid = guestPayments[0].isPaid
            if (isPaid !== undefined && isPaid) {
              return Promise.resolve(true)
            }
          }
        }
      }
      return Promise.resolve(false)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  /**
   * 执行扫码支付路径解析逻辑
   *
   * @returns {Promise<ResolveResult>} 路径解析结果
   * @description
   * - 验证必要参数: type(t), shopId(rid), orderType和autoId
   * - 检查type是否为扫码支付类型(QRCodeType.ScanPay)
   * - 对于堂食订单(orderType=1)：
   *   - 检查用户是否已有关联订单
   *   - 已有订单且已支付：跳转到支付完成页
   *   - 已有订单且未支付：跳转到订单信息页
   *   - 无关联订单：调用扫码支付API并跳转到订单检查页
   * - 对于其他订单类型：直接调用扫码支付API并跳转到订单检查页
   *
   * @throws {Error} 处理各种错误情况：
   *  - 30006: Firebase故障
   *  - 50046: 二维码已过期
   *  - 50007: 已支付
   *  - 50048: 已关单
   */
  async resolvePath(): Promise<ResolveResult> {
    const {
      t: type,
      rid: shopId,
      orderType,
      autoId
    } = this._pathQuery || {}

    if (type !== undefined && shopId !== undefined && orderType !== undefined && autoId !== undefined && parseInt(type) === QRCodeType.ScanPay) {
      const orderTypeNumber = parseInt(orderType)

      try {
        // 1为DineInOrder
        if (orderTypeNumber === 1) {
          // 需先判断是否之前已经有手机单子（扫码开台或者后面加入订单）
          // 有订单：未支付，进入：orderReviewInformation；已支付，进入：已结账订单详情页面
          // 无订单：先调用接口：scanPayOrder(shopId, autoId, orderType, timestamp)，再进入：orderReviewInformation
          const shopAutoId = await ScanPayResolver._isDineInOrderExisted(shopId)

          if (shopAutoId !== undefined) {
            const dineInOrderCheckModel = await this._fetchOrderInformation({
              restaurantId: shopAutoId.shopId,
              autoId: shopAutoId.autoId
            })

            if (dineInOrderCheckModel !== undefined) {
              const isPaid = await ScanPayResolver._fetchOrderIsPaid(shopAutoId.shopId, shopAutoId.autoId)
              const tableInfo = dineInOrderCheckModel.tableInfo
              const sessionDineInModel = buildSessionDineOrder(shopId, shopAutoId.autoId, tableInfo.tableId,
                tableInfo.tableName, dineInOrderCheckModel.userCount, dineInOrderCheckModel.preOrder,
                dineInOrderCheckModel.isDineIn, true, false, tableInfo.tableGratuities, isPaid)

              OrderInformationManager.instance().setSessionDineInOrder(sessionDineInModel)
              if (isPaid) {
                return Promise.resolve({
                  isResolved: true,
                  pathName: 'payComplete'
                })
              } else {
                return Promise.resolve({
                  isResolved: true,
                  pathName: 'orderReviewInformation'
                })
              }
            }
          } else {
            await scanPayOrder(shopId, autoId, orderType)
            return Promise.resolve({
              isResolved: true,
              pathName: 'checkOrderInfo',
              params: {
                shopId,
                autoId,
                tableOrderType: orderTypeNumber,
                orderType
              }
            })
          }
        } else {
          await scanPayOrder(shopId, autoId, orderType)
          return Promise.resolve({
            isResolved: true,
            pathName: 'checkOrderInfo',
            params: {
              shopId,
              autoId,
              tableOrderType: orderTypeNumber,
              orderType
            }
          })
        }
      } catch (error) {
        let errMsg = ''
        switch ((error as { code: number }).code) {
          case 30006: // firebase故障
            return Promise.reject(error)
          case 50046: // 二维码已过期
            errMsg = 'codeExpired'
            break
          case 50007: // 已支付
            return Promise.resolve({
              isResolved: true,
              pathName: 'orderInvoicePaid'
            })
          case 50048: // 已关单
            errMsg = 'orderVoided'
            break
          default:
            errMsg = 'requestFail'
            break
        }

        return Promise.reject(new Error(errMsg))
      }
    }

    return Promise.resolve({ isResolved: false })
  }
}
