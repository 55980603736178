import { PaymentError, PointsError } from '../error-code'

export default class APIError extends Error {
  code: number
  restaurantId?: string

  constructor(code: number, message?: string, restaurantId?: string) {
    super(message)
    this.code = code
    this.restaurantId = restaurantId

    // 处理特殊错误消息
    if (code === PointsError.InsufficientPoints && message) {
      // 对于积分余额不足错误，message包含可用积分金额
      // 格式：'status code: 60402, message: 1000'，其中1000是可用积分
      try {
        const availablePoints = message.split(',')[1].split(':')[1].trim()
        this.message = `积分余额不足，当前可用积分: ${availablePoints}`
      } catch (e) {
        // 如果解析失败，保留原始消息
        this.message = message || '积分余额不足'
      }
    }
  }

  isCardMismatched(): boolean {
    return this.code === PaymentError.CardMismatched
  }

  isPriceChanged(): boolean {
    return this.code === PaymentError.PriceChanged
  }

  isPaymentOnProcess(): boolean {
    return this.code === PaymentError.PaymentOnProcess
  }

  isDuplicatePayment(): boolean {
    return this.code === PaymentError.DuplicatePayment
  }

  isInsufficientPoints(): boolean {
    return this.code === PointsError.InsufficientPoints
  }

  getAvailablePoints(): number | null {
    if (this.isInsufficientPoints() && this.message) {
      try {
        const messageStr = this.message
        if (messageStr.includes('当前可用积分')) {
          const pointsStr = messageStr.split('当前可用积分:')[1].trim()
          return parseInt(pointsStr)
        }
      } catch (e) {
        // 解析失败返回null
      }
    }
    return null
  }
}
