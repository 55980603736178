enum QRCodeType {
  DineInOrder = 1,
  Waitlist = 2,
  OnlineOrder = 3,
  Login = 4,
  FastOrder = 5,
  MenuOrder = 6,
  ViewMenu = 8,
  ShopDetail = 9,
  ScanPay = 10,
  GiftCardFromFriend = 11,
  PCOnlineOrder = 12,
  WaitlistDetail = 13
}

export default QRCodeType
