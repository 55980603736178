import Resolver from '@/views/app/utils/path-resolver/resolvers/Resolver'
import ResolveResult from '@/views/app/utils/path-resolver/resolvers/ResolveResult'
import { fetchShopIdByDomain } from '@/views/app/utils/path-resolver/api'
import ShopIdManager from '@/utils/shop-id-manager'

/**
 * 订单功能路径解析器，处理包含店铺标识的路径解析
 *
 * @class OrderFunctionsPathResolver
 * @extends Resolver
 * @description 专用于处理订单功能相关路径解析，主要职责：
 * - 解析包含'shop_name'参数的请求
 * - 将店铺名称/域名转换为店铺ID
 * - 管理店铺ID的缓存和扫描状态
 * - 跳转至订单功能页面
 */
export default class OrderFunctionsPathResolver extends Resolver {
  /**
   * 执行订单功能路径解析的核心逻辑
   *
   * @override
   * @returns {Promise<ResolveResult>} 解析结果对象，包含：
   * - isResolved: 是否成功解析路径
   * - pathName: 目标路由名称（固定为'orderFunctions'）
   * @throws {Error} 当发生网络请求错误或数据格式异常时抛出
   * @description 解析流程：
   * 1. 验证路径查询参数有效性
   * 2. 提取shop_name参数
   * 3. 判断是否需要通过API转换域名到店铺ID
   * 4. 存储店铺ID和扫描状态到全局管理器
   * 5. 返回路由跳转指令
   */
  async resolvePath(): Promise<ResolveResult> {
    const pathQuery = this._pathQuery

    if (pathQuery !== undefined) {
      const shopName = pathQuery.shop_name

      if (shopName !== undefined) {
        try {
          let shopId = shopName
          // 使用正则校验19位纯数字店铺ID格式，避免不必要的API调用
          if (!/^\d{19}$/.test(shopName)) {
            const { data } = await fetchShopIdByDomain(shopId)
            shopId = data.id
          }

          if (shopId !== undefined) {
            // 更新全局店铺管理器的扩展信息
            ShopIdManager.instance().extras.shopId = shopId
            ShopIdManager.instance().extras.scan = pathQuery.scan

            return Promise.resolve({
              isResolved: true,
              pathName: 'orderFunctions'
            })
          }
        } catch (e) {
          return Promise.reject(e)
        }
      }
    }
    return Promise.resolve({
      isResolved: false
    })
  }
}
