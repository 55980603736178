import Resolver from './Resolver'
import ResolveResult from './ResolveResult'
import QRCodeType from '../models/QRCodeType'
import { fetchMenuQRCodeMenuId } from '../api'
import ResolveErrorType from '../models/ResolveErrorType'
import OrderInformationManager from '@/utils/order-information-manager'
import { currentMenuId } from '@/utils/menu-manager/utils/menu-builder'
import { genFastOrderAutoId } from '@/utils/id-generator'
import RestaurantInfoManager from '@/views/restaurant-information/utils/restaurant-info-manager'
import TimeManager from '@/network/utils/TimeManager'
import {
  buildSessionMenuOrder,
  buildSessionViewMenuOrder
} from '@/utils/order-information-manager/utils/build-session-order-model'
import ShopInformationManager from '@/utils/shop-information-manager/ShopInformationManager'

/**
 * 菜单订单路径解析器，处理菜单二维码的解析与验证
 *
 * @class MenuOrderPathResolver
 * @extends Resolver
 * @description
 * - 处理类型为QRCodeType.MenuOrder和QRCodeType.ViewMenu的二维码
 * - 支持普通点餐菜单和仅查看菜单两种模式
 * - 验证二维码是否过期和有效
 * - 验证店铺支付设置，并创建相应的会话订单
 */
export default class MenuOrderPathResolver extends Resolver {
  /**
   * 执行菜单订单路径解析逻辑
   *
   * @returns {Promise<ResolveResult>} 路径解析结果
   * @description
   * - 验证必要参数: type(t), shopId(rid), code和timestamp(ts)
   * - 检查type是否为菜单订单类型(QRCodeType.MenuOrder或QRCodeType.ViewMenu)
   * - 验证二维码是否过期(超过2小时)
   * - 获取二维码关联的菜单ID
   * - 验证二维码是否有效
   * - 根据类型创建不同的会话订单:
   *   - ViewMenu: 创建仅查看菜单的会话订单
   *   - MenuOrder: 创建可点餐的菜单会话订单，并验证支付设置
   * - 成功时跳转到点餐菜单页
   *
   * @throws {Error} 当二维码过期、无效或支付功能未启用时抛出错误
   */
  async resolvePath(): Promise<ResolveResult> {
    if (this._pathQuery !== undefined) {
      const {
        t,
        rid: shopId,
        code,
        ts: timestamp
      } = this._pathQuery

      if (t !== undefined && shopId !== undefined && code !== undefined) {
        const orderType = parseInt(t)
        if (orderType !== QRCodeType.MenuOrder && orderType !== QRCodeType.ViewMenu) {
          return Promise.resolve({ isResolved: false })
        } else {
          if (timestamp !== undefined && TimeManager.instance().currentMSTimestamp >= parseInt(timestamp) + 7200000) {
            return Promise.reject(new Error('codeExpired'))
          }
          try {
            const { data } = await fetchMenuQRCodeMenuId(code)
            const {
              menuId,
              isEnabled
            } = data

            if (isEnabled) {
              if (orderType === QRCodeType.ViewMenu) {
                OrderInformationManager.instance().setSessionViewMenuOrder(buildSessionViewMenuOrder(shopId, menuId))
              } else {
                const information = await ShopInformationManager.instance().fetchShopInformation(shopId)
                const paymentSetting = information.paymentSetting
                const { isYammiiPaymentEnabled, isMobilePaymentEnabled } = paymentSetting

                if (!isYammiiPaymentEnabled || !isMobilePaymentEnabled) {
                  RestaurantInfoManager.instance().setParams(shopId, '1')
                  return Promise.resolve({
                    isResolved: true,
                    pathName: 'restaurantInformation'
                  })
                }

                const validMenuId = await currentMenuId(shopId, false)
                if (validMenuId === undefined) {
                  RestaurantInfoManager.instance().setParams(shopId, '1')
                  return Promise.resolve({
                    isResolved: true,
                    pathName: 'restaurantInformation'
                  })
                }

                const autoId = genFastOrderAutoId(shopId)
                OrderInformationManager.instance().setSessionMenuOrder(buildSessionMenuOrder(shopId, menuId, autoId))
              }

              return {
                isResolved: true,
                pathName: 'shopOrderMenu'
              }
            }
            return Promise.reject(new Error(JSON.stringify({
              type: orderType,
              error: ResolveErrorType.QRCodeNotEnabled
            })))
          } catch (e) {
            return Promise.reject(e)
          }
        }
      }
    }

    return Promise.resolve({ isResolved: false })
  }
}
