import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "font-16-bold-333 title-text" }
const _hoisted_3 = { class: "font-14-999" }
const _hoisted_4 = {
  key: 1,
  class: "claim-mask"
}
const _hoisted_5 = {
  class: "font-16-333",
  style: {"text-align":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_nav = _resolveComponent("page-nav")!
  const _component_giftcard_accept = _resolveComponent("giftcard-accept")!
  const _component_page_layout = _resolveComponent("page-layout")!
  const _component_common_button = _resolveComponent("common-button")!
  const _component_common_modal = _resolveComponent("common-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.giftCardInfo !== undefined)
      ? (_openBlock(), _createBlock(_component_page_layout, {
          key: 0,
          "is-show-footer-indicator": false,
          "is-fixed-bottom-navigation-bar": false
        }, {
          header: _withCtx(() => [
            _createVNode(_component_page_nav, {
              isShowBack: false,
              isBackgroundTrans: ""
            })
          ]),
          body: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_giftcard_accept, {
                item: _ctx.giftCardInfo,
                onRefuse: _ctx.refuse,
                onAccept: _cache[0] || (_cache[0] = ($event: any) => (_ctx.claim(true)))
              }, null, 8 /* PROPS */, ["item", "onRefuse"]),
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.t('useGiftCardTitle')), 1 /* TEXT */),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.t('useGiftCardDesc')), 1 /* TEXT */)
            ])
          ]),
          _: 1 /* STABLE */
        }))
      : (_ctx.unclaimedGiftCardInfo !== undefined)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _withDirectives(_createVNode(_component_giftcard_accept, {
              item: _ctx.unclaimedGiftCardInfo,
              onRefuse: _ctx.refuse,
              onAccept: _cache[1] || (_cache[1] = ($event: any) => (_ctx.claim(true)))
            }, null, 8 /* PROPS */, ["item", "onRefuse"]), [
              [_vShow, _ctx.showCardRef]
            ])
          ]))
        : _createCommentVNode("v-if", true),
    _createVNode(_component_common_modal, {
      visible: _ctx.refuseVisibleRef,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.refuseVisibleRef) = $event)),
      "is-title-show": false,
      closable: false,
      width: "275px"
    }, {
      "footer-left-right": _withCtx(() => [
        _createVNode(_component_common_button, {
          size: "small",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.refuseVisibleRef = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('No')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_common_button, {
          type: "primary",
          size: "small",
          onClick: _ctx.confirmRefuse
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('Yes')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.t('RefuseGiftCard')), 1 /* TEXT */),
        _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1 /* HOISTED */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["visible"])
  ], 64 /* STABLE_FRAGMENT */))
}