import ResolverManager from '../'
import ResolveResult from './ResolveResult'

/**
 * 路径解析器的抽象基类，实现责任链模式的核心逻辑
 *
 * @abstract
 * @class Resolver
 * @description
 * - 提供解析器链的构建能力
 * - 实现基础的错误处理机制
 * - 管理解析器之间的调用顺序
 */
export default abstract class Resolver {
  /** 责任链中的下一个解析器实例 */
  private _nextResolver?: Resolver
  /** 当前解析器需要处理的路径查询参数 */
  protected _pathQuery?: {[key: string]: string}

  /**
   * 设置路径查询参数
   * @param {Object.<string, string>} query - 从URL解析得到的键值对参数
   */
  set pathQuery (query: {[key: string]: string}) {
    this._pathQuery = query
  }

  /**
   * 设置责任链中的下一个解析器
   * @param {Resolver} resolver - 下一个要执行的解析器实例
   */
  set nextResolver (resolver: Resolver) {
    this._nextResolver = resolver
  }

  constructor (resolverManager: ResolverManager) {
    resolverManager.addResolver(this)
  }

  /**
   * 抽象方法 - 子类必须实现的具体路径解析逻辑
   * @abstract
   * @returns {Promise<ResolveResult>} 解析结果对象
   */
  abstract resolvePath (): Promise<ResolveResult>;

  /**
   * 执行解析流程，实现责任链模式的核心调用逻辑
   * @returns {Promise<ResolveResult>} 解析结果对象
   * @throws {Error} 当没有可用的路径查询参数时抛出错误
   * @description
   * - 优先执行当前解析器的resolvePath()
   * - 若当前解析器未能解析成功，则调用下一个解析器
   * - 当所有解析器都无法解析时返回isResolved=false
   * - 统一处理子类可能抛出的异常
   */
  async resolve (): Promise<ResolveResult> {
    if (this._pathQuery !== undefined) {
      try {
        const resolveResult = await this.resolvePath()

        if (resolveResult.isResolved) {
          return Promise.resolve(resolveResult)
        } else {
          if (this._nextResolver !== undefined) {
            const nextResolveResult = await this._nextResolver.resolve()
            return Promise.resolve(nextResolveResult)
          }

          return Promise.resolve({ isResolved: false })
        }
      } catch (e) {
        return Promise.reject(e)
      }
    }

    return Promise.reject(new Error('No path query available'))
  }
}
