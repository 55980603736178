import QRCodeType from '../models/QRCodeType'
import Resolver from './Resolver'
import ResolveResult from './ResolveResult'
import { unclaimedGiftCard } from '@/views/app/utils/path-resolver/api'

/**
 * 礼品卡路径解析器，专门处理来自朋友的礼品卡二维码路径解析
 *
 * @class GiftCardPathResolver
 * @extends Resolver
 * @description
 * - 识别并处理类型为QRCodeType.GiftCardFromFriend的二维码
 * - 处理礼品卡领取流程的参数验证和API调用
 * - 根据API响应结果返回不同的错误状态码：
 *   - 50051: 礼品卡已被确认
 *   - 50045: 礼品卡已过期
 *   - 其他错误: 请求失败
 */
export default class GiftCardPathResolver extends Resolver {
  /**
   * 执行礼品卡路径解析逻辑
   *
   * @returns {Promise<ResolveResult>} 路径解析结果
   * @description
   * - 验证必须参数: type(t) 和 rid(giveId)
   * - 检查type是否为礼品卡类型(QRCodeType.GiftCardFromFriend)
   * - 调用未领取礼品卡API获取详细信息
   * - 成功时返回礼品卡领取页面路径和参数
   * - 处理API错误并转换为对应的错误状态码
   *
   * @throws {Error} 当API返回特定错误码时抛出包含状态码标识的错误对象
   */
  async resolvePath(): Promise<ResolveResult> {
    const { t: type, rid: giveId } = this._pathQuery || {}

    if (type !== undefined && giveId !== undefined && parseInt(type) === QRCodeType.GiftCardFromFriend) {
      try {
        const { data } = await unclaimedGiftCard(giveId)
        return Promise.resolve({ isResolved: true, pathName: 'giftcardClaim', params: { giftCardInfo: JSON.stringify(data) } })
      } catch (error) {
        return Promise.reject(new Error((error as { code: number }).code === 50051 ? 'giftCardConfirmed' : ((error as { code: number }).code === 50045 ? 'giftCardExpired' : 'requestFail')))
      }
    }

    return Promise.resolve({ isResolved: false })
  }
}
