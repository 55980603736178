import OrderSubmission from '@/views/order-information/models/OrderSubmission'
import OrderMenuPreOrderType from '@/utils/order-information-manager/models/OrderMenuPreOrderType'

function isDineInOrderProOrder(submissions: OrderSubmission[]): OrderMenuPreOrderType {
  const submissionsLength = submissions.length

  if (submissionsLength === 0) {
    return OrderMenuPreOrderType.PreOrder
  }

  if (submissionsLength === 1) {
    const submission = submissions[0]

    if (submission.isSubmitted) {
      return OrderMenuPreOrderType.NoPreOrder
    } else {
      return OrderMenuPreOrderType.PreOrder
    }
  }

  return OrderMenuPreOrderType.NoPreOrder
}

export { isDineInOrderProOrder }
