import Resolver from './Resolver'
import ResolveResult from './ResolveResult'
import QRCodeType from '../models/QRCodeType'
import { fetchQrCodeResult } from '../api'
import ResolveErrorType from '../models/ResolveErrorType'
import ShopInformationManager from '@/utils/shop-information-manager/ShopInformationManager'
import { currentMenuId } from '@/utils/menu-manager/utils/menu-builder'
import RestaurantInfoManager from '@/views/restaurant-information/utils/restaurant-info-manager'
import TimeManager from '@/network/utils/TimeManager'
import GuestMobileOrderingMode from '@/utils/shop-information-manager/models/GuestMobileOrderingMode'
import OrderInformationManager from '@/utils/order-information-manager'
import OrderMenuPreOrderType from '@/utils/order-information-manager/models/OrderMenuPreOrderType'
import OrderMenuType from '@/utils/order-information-manager/models/OrderMenuType'
import { genFastOrderAutoId } from '@/utils/id-generator'

/**
 * 快速订单路径解析器，处理快速点餐二维码的解析与验证
 *
 * @class FastOrderPathResolver
 * @extends Resolver
 * @description
 * - 处理类型为QRCodeType.FastOrder的二维码
 * - 验证店铺是否启用餐桌二维码功能
 * - 根据店铺设置的顾客移动点餐模式返回不同的页面路径
 * - 支持堂食和外带两种快速点餐模式
 */
export default class FastOrderPathResolver extends Resolver {
  /**
   * 执行快速订单路径解析逻辑
   *
   * @returns {Promise<ResolveResult>} 路径解析结果
   * @description
   * - 验证必要参数: type(t), shopId(rid), code和timestamp(ts)
   * - 检查type是否为快速订单类型(QRCodeType.FastOrder)
   * - 验证二维码是否过期(超过2小时)
   * - 验证店铺是否启用餐桌二维码功能
   * - 验证二维码是否有效
   * - 根据店铺的顾客移动点餐模式设置不同的跳转路径:
   *   - 堂食或外带模式: 创建无桌快速订单并跳转到点餐菜单页
   *   - 其他模式: 跳转到快速服务订单页
   *
   * @throws {Error} 当餐桌二维码功能未启用、二维码过期或无效时抛出错误
   */
  async resolvePath(): Promise<ResolveResult> {
    if (this._pathQuery !== undefined) {
      const {
        t: orderType,
        rid: shopId,
        code,
        ts: timestamp
      } = this._pathQuery

      if (orderType !== undefined && shopId !== undefined && code !== undefined) {
        if (parseInt(orderType) !== QRCodeType.FastOrder) {
          return Promise.resolve({ isResolved: false })
        } else {
          if (timestamp !== undefined && TimeManager.instance().currentMSTimestamp >= parseInt(timestamp) + 7200000) {
            return Promise.reject(new Error('codeExpired'))
          }
          try {
            const shopInformation = await ShopInformationManager.instance().fetchShopInformation(shopId)
            if (!shopInformation.posModule.isTableQRCodeEnabled) {
              return Promise.reject(new Error('tableQRCodeNotEnabled'))
            }

            const menuId = await currentMenuId(shopId, false)
            if (menuId === undefined) {
              RestaurantInfoManager.instance().setParams(shopId, '1')
              return Promise.resolve({
                isResolved: true,
                pathName: 'restaurantInformation'
              })
            }
            const { data } = await fetchQrCodeResult(code)
            const {
              isEnabled
            } = data

            if (isEnabled) {
              const guestMobileOrderingMode = shopInformation.mobileOrderSetting.guestMobileOrderingMode

              if (guestMobileOrderingMode === GuestMobileOrderingMode.toGo || guestMobileOrderingMode === GuestMobileOrderingMode.dineIn) {
                OrderInformationManager.instance().setSessionNoTableFastOrder({
                  shopId,
                  preOrder: OrderMenuPreOrderType.PreOrder,
                  isDineIn: guestMobileOrderingMode === GuestMobileOrderingMode.dineIn,
                  orderType: OrderMenuType.NoTableFast,
                  isShowBack: true,
                  autoId: genFastOrderAutoId(shopId),
                  isPaid: false
                })
                return {
                  isResolved: true,
                  pathName: 'shopOrderMenu'
                }
              } else {
                return {
                  isResolved: true,
                  pathName: 'quickServiceOrder',
                  params: { shopId }
                }
              }
            }
            return Promise.reject(new Error(JSON.stringify({
              type: QRCodeType.FastOrder,
              error: ResolveErrorType.QRCodeNotEnabled
            })))
          } catch (e) {
            return Promise.reject(e)
          }
        }
      }
    }

    return Promise.resolve({ isResolved: false })
  }
}
