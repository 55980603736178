import OrderMenuCategory from './OrderMenuCategory'
import Option from '@/utils/menu-manager/models/menu/models/Option'
import Modifier from '@/utils/menu-manager/models/menu/models/Modifier'
import { Item } from '@/utils/menu-manager/models/item'
import ItemLanguage from '@/utils/menu-manager/models/item-languages/ItemLanguage'

export default class OrderMenu {
  name = ''
  menuId = ''
  categories: OrderMenuCategory[] = []
  options: Option[] = []
  modifiers: Modifier[] = []
  items: Item[] = []
  itemLanguages: ItemLanguage[] = []
}
