import { ChannelCacheData, ChannelDto } from '@/network/api/models/ChannelDto'
import Keys from '../local-storage-manager/Keys'
import { getChannels } from '@/network/api/channel'

/** 缓存过期时间（1小时） */
const CACHE_EXPIRATION_TIME = 60 * 60 * 1000

/**
 * 渠道缓存管理器
 * 负责管理渠道信息的缓存获取和更新
 */
export default class ChannelCache {
  private static _instance: ChannelCache

  static instance(): ChannelCache {
    if (!this._instance) {
      this._instance = new ChannelCache()
    }
    return this._instance
  }

  /**
   * 从缓存中获取渠道信息
   * @returns 缓存的渠道信息或null
   */
  private getCachedChannels(): ChannelCacheData | null {
    const cachedData = localStorage.getItem(Keys.CHANNEL_CACHE)
    if (!cachedData) return null
    return JSON.parse(cachedData) as ChannelCacheData
  }

  /**
   * 将渠道信息存入缓存
   * @param channels 渠道信息列表
   */
  private setCachedChannels(channels: ChannelDto[]): void {
    const cacheData: ChannelCacheData = {
      data: channels,
      timestamp: Date.now()
    }
    localStorage.setItem(Keys.CHANNEL_CACHE, JSON.stringify(cacheData))
  }

  /**
   * 检查缓存是否过期
   * @param timestamp 缓存时间戳
   * @returns 是否过期
   */
  private isCacheExpired(timestamp: number): boolean {
    return Date.now() - timestamp > CACHE_EXPIRATION_TIME
  }

  /**
   * 获取渠道信息
   * 优先从缓存获取，如果缓存过期或不存在则从服务器获取
   * @returns Promise<ChannelDto[]>
   */
  async getChannels(): Promise<ChannelDto[]> {
    const cachedData = this.getCachedChannels()

    if (cachedData && !this.isCacheExpired(cachedData.timestamp)) {
      return cachedData.data
    }

    try {
      const response = await getChannels()
      if (response.data) {
        this.setCachedChannels(response.data)
        return response.data
      }
      return []
    } catch (error) {
      console.error('获取渠道信息失败:', error)
      // 如果请求失败但有缓存数据，返回缓存数据
      return cachedData?.data || []
    }
  }

  /**
   * 清除渠道缓存
   */
  clearCache(): void {
    localStorage.removeItem(Keys.CHANNEL_CACHE)
  }
}
