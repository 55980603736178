import { RouteLocationRaw, Router } from 'vue-router'
import { Ref } from 'vue'

export default class RouterUtil {
  private static _routerUtil: RouterUtil

  static instance(): RouterUtil {
    if (this._routerUtil === undefined) {
      this._routerUtil = new RouterUtil()
    }

    return this._routerUtil
  }

  private _isSpinRef: Ref<boolean> | undefined
  private _router: Router | undefined
  private _count: number

  updateSpinRefAndRouter(isSpinRef: Ref<boolean>, router: Router): void {
    this._isSpinRef = isSpinRef
    this._router = router
  }

  private constructor() {
    this._isSpinRef = undefined
    this._router = undefined
    this._count = 0
  }

  async push(to: RouteLocationRaw): Promise<void> {
    if (this._isSpinRef !== undefined && this._router !== undefined) {
      try {
        this._isSpinRef.value = true
        await this._router.push(to)
        this._isSpinRef.value = false
        this._count += 1
      } catch (e) {
        this._isSpinRef.value = false
      }
    }
  }

  async replace(to: RouteLocationRaw): Promise<void> {
    if (this._isSpinRef !== undefined && this._router !== undefined) {
      try {
        this._isSpinRef.value = true
        await this._router.replace(to)
        this._isSpinRef.value = false
      } catch (e) {
        this._isSpinRef.value = false
      }
    }
  }

  back(): void {
    if (this._router !== undefined) {
      if (this._count !== 0) {
        this._router.back()
        this._count -= 1
      } else {
        this._router.replace({
          name: 'Home'
        }).then(() => {
          this._count = 0
        })
      }
    }
  }

  go(delta: number): void {
    if (this._router !== undefined) {
      if (this._count !== 0) {
        this._router.go(delta)
        this._count -= delta

        if (this._count < 0) {
          this._router.replace({
            name: 'Home'
          }).then(() => {
            this._count = 0
          })
        }
      }
    }
  }
}
