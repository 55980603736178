import API from '@/network/API'
import { CustomSuccessData } from 'axios'

const _firebaseUrl = '/restaurantOrders/v1.0/v1/firebase/restaurants'

async function scanPayOrder(shopId: string, autoId: string, orderType: string): Promise<CustomSuccessData<void>> {
  return API.instance().get<void>(`${_firebaseUrl}/${shopId}/paymentOrders/${autoId}`, { orderType })
}

export {
  scanPayOrder
}
